import { IUploadedSuSa } from "../_lib/hooks";
import { IRevenueRatios } from "./plans";
import { FileUploadFormat, FileUploadVariant, IArrangementKeys } from "./uploads";
import ELegalForms from "../quick/Public/Register";

export interface AuthError {
  //__typename: TypeNameEnums.AUTH_ERROR;
  code: AuthEnums;
  name: string;
  message?: { [key: string]: string };
}
export interface IUserInfo {
  //__typename: TypeNameEnums.CURRENT_USER;
  authenticating: boolean;
  username: string;
  email: string;
  temporaryPassword: string;
  authenticated: boolean;
  forceChange: boolean;
  errors: AuthError[];
  selectedClient: ISelectedClient;
  clients: Client[];
  state: CustomerState;
  attributes: {
    //__typename: TypeNameEnums.USER_ATTRIBUTES;
    customer_id?: string;
    user_id: string;
    name: string;
    sub?: string;
  };
  appUser: AppUser;
}
export interface ISelectedClient {
  client_id: string | null;
  state: SelectClientState;
  kpis: string[];
  uploads?: { loading?: boolean; list?: IUploadedSuSa[] };
  evaluation?: {
    currentGrade?: number;
    prevGrade?: number;
    pending?: boolean;
  };
}

export enum CustomerState {
  UNKNOWN = "UNKNOWN",
  PENDING = "PENDING",
  ERROR = "ERROR",
  READY = "READY",
}
export enum SelectClientState {
  UNKNOWN = "UNKNOWN",
  SELECTABLE = "SELECTABLE",
  INITIAL = "INITIAL",
  PENDING = "PENDING",
  DRAFT = "DRAFT",
  READY = "READY",
}

export interface AppUser {
  //__typename: TypeNameEnums.APP_NAME;
  user_id: string;
  customer_id: string;
  alias: string;
  email: string;
  root: boolean;
  links?: RelationLink[];
  customer: Customer;
  clients: string[];
}
export interface Customer {
  //__typename: TypeNameEnums.CUSTOMER;
  customer_id: string;
  name: string;
  type: CustomerType;
  api_access: boolean;
  address: IAddressData;
  contact: IContactData;
  ou: string;
  links?: RelationLink[];
}

export interface IContactData {
  first_name: string;
  last_name: string;
  telephone: string;
}
export interface Client {
  //__typename: TypeNameEnums.SELECTED_CLIENT;
  client_id: string;
  name: string;
  category?: string;
  fiscal_year: string;
  legal_form?: ELegalForms;
  mapping?: AccountMappingType;
  address: IAddressData;
  links: RelationLink[];
  customization: ClientCustomizations;
}
export interface ClientInput {
  customer_id: string;
  client_id: string;
  name: string;
  address: IAddressData;
  fiscal_year: string;
  legal_form?: ELegalForms;
  category?: string;
  mapping: AccountMappingType;
  customization: ClientCustomizations;
  links: RelationLink[];
}
/*
export enum AccountStatus {
  draft = "draft",
  confirmed = "confirmed",
  retired = "retired",
  mock = "mock",
}
*/

export enum AccountMappingType {
  UNK00N = "UNK00N",
  SKR03K = "SKR03K",
  SKR03P = "SKR03P",
  SKR04K = "SKR04K",
  SKR04P = "SKR04P",
  SKR45P = "SKR45P",
  SKR49 = "SKR49",
  SKR51K = "SKR51K",
  SKRAT01 = "SKRAT01",
}

export interface IAddressData {
  line_1: string | null;
  line_2: string | null;
  city: string | null;
  telephone: string | null;
  postal: string | null;
  country: Country;
}
export interface ClientCustomizations {
  report_color: string | null;
  dashboard_kpis: string[] | null;
  upload_settings?: IUploadSettings;
  plan_settings: IRevenuePlan;
  // logo: string | null;
}

export interface IUploadSettings {
  format: FileUploadFormat | null;
  variant_type: FileUploadVariant;
  delimiter?: string;
  quote?: string;
  sheet_name?: string;
  mapping?: IArrangementKeys;
}

export interface IRevenuePlan {
  revenue_curve: ERevenueCurves;
  revenue_growth: number;
  revenue_ratios: IRevenueRatios;
}
export interface DraftClient extends Omit<Client, "status" | "links" | "client_id"> {
  //__typename: TypeNameEnums.DRAFT_CLIENT;
  customer_id: string;
  client_id: string | null;
  category_type: string;
  errors: DraftClientMissingFields[];
  address: IAddressData;
  state: DraftClientSetup;
}

export enum DraftClientSetup {
  DATA = "DATA",
  UPLOAD = "UPLOAD",
  DATA_PROGRESS = "DATA_PROGRESS",
  UPLOAD_PROGRESS = "UPLOAD_PROGRESS",
}
export enum DraftClientMissingFields {
  NAME = "name",
  CATEGORY = "category",
  FISCAL_YEAR = "fiscal_year",
  MAPPING = "mapping",
}

export interface IActualSuSa {
  susa_id: string;
  date: string;
  format: string;
  client_id: string;
  calculated_at: string;
  links: RelationLink[];
}
export interface ActualSuSaData {
  global_code_id: string;
  balance: number;
  starting_balance: number;
  balance_cumulated: number;
  sum: number;
  ratio: number;
  details: ActualDetails[];
}
export interface ActualDetails {
  account_code: number;
  description: string;
  balance: number;
  starting_balance: number;
}
export interface TimeSeriesActuals {
  calculated: boolean;
  date: string;
  susa_id: string;
  data: ActualSuSaData[];
}
export interface ICalculatedSuSa extends Omit<IActualSuSa, "calculated_at"> {
  uploaded_at: string;
}
export interface RelationLink {
  rel: Relation;
  href: string;
  format?: FileUploadFormat;
}

export enum Relation {
  self = "self",
  client = "client",
  customer = "customer",
  user = "user",
  kpi = "kpi",
  video = "video",
  poster = "poster",
  formula = "formula",
  render = "render",
  report = "report",
}

export enum CustomerType {
  direct,
  agency,
}

export enum Country {
  AUT = "AUT",
  DEU = "DEU",
}
export enum ERevenueCurves {
  OF001 = "OF001",
  OF002 = "OF002",
  OF003 = "OF003",
  OF004 = "OF004",
  OF005 = "OF005",
  OF006 = "OF006",
  OF007 = "OF007",
}

export enum UserStateActionTypes {
  CLEAR_STORE = "CLEAR_STORE",
  CURRENT_USER = "CURRENT_USER",
  CURRENT_USER_STATE = "CURRENT_USER_STATE",
  UPDATE_CLIENTS_LIST = "UPDATE_CLIENTS_LIST",
  SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT",
  SET_SELECTED_CLIENT_UNKNOWN = "SET_SELECTED_CLIENT_UNKNOWN",
  SET_SELECTED_CLIENT_INITIAL = "SET_SELECTED_CLIENT_INITIAL",
  SET_SELECTED_CLIENT_PENDING = "SET_SELECTED_CLIENT_PENDING",
  SET_SELECTED_CLIENT_DRAFT = "SET_SELECTED_CLIENT_DRAFT",
  SET_SELECTED_CLIENT_READY = "SET_SELECTED_CLIENT_READY",
  SET_DRAFT_CLIENT = "SET_DRAFT_CLIENT",
  RESET_DRAFT_CLIENT = "RESET_DRAFT_CLIENT",
  UPDATE_SELECTED_CLIENT_DATA = "UPDATE_SELECTED_CLIENT_DATA",
  UPDATE_KPI_LIST = "UPDATE_KPI_LIST",
  UPDATE_UPLOAD_SETTINGS = "UPDATE_UPLOAD_SETTINGS",
  UPDATE_UPLOADS_LIST = "UPDATE_UPLOADS_LIST",
  UPDATE_PLAN_SETTINGS = "UPDATE_PLAN_SETTINGS",
  UPDATE_EVALUATION_DATA = "UPDATE_EVALUATION_DATA",
  SET_NEW_SUSA_UPLOADED = "SET_NEW_SUSA_UPLOADED",
  SET_WEEKLY_LIQUIDITY_PLAN = "SET_WEEKLY_LIQUIDITY_PLAN",
  SET_MONTHLY_LIQUIDITY_PLAN = "SET_MONTHLY_LIQUIDITY_PLAN",
  SET_SUSA_DELETION_RUNNING = "SET_SUSA_DELETION_RUNNING",
  SET_PROFITLOSS_PLAN = "SET_PROFITLOSS_PLAN",
  SET_CONVERSION_METADATA = "SET_CONVERSION_METADATA",
}
export enum SelectedClientActionTypes {
  CLEAR_STORE = "CLEAR_STORE",
  SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT",
  SET_SELECTED_CLIENT_UNKNOWN = "SET_SELECTED_CLIENT_UNKNOWN",
  SET_SELECTED_CLIENT_INITIAL = "SET_SELECTED_CLIENT_INITIAL",
  SET_SELECTED_CLIENT_PENDING = "SET_SELECTED_CLIENT_PENDING",
  SET_SELECTED_CLIENT_DRAFT = "SET_SELECTED_CLIENT_DRAFT",
  SET_SELECTED_CLIENT_READY = "SET_SELECTED_CLIENT_READY",
}
export enum DraftClientStateActionTypes {
  SET_DRAFT_CLIENT = "SET_DRAFT_CLIENT",
  RESET_DRAFT_CLIENT = "RESET_DRAFT_CLIENT",
  CLEAR_STORE = "CLEAR_STORE",
}

export enum ThemeStateActionTypes {
  SET_THEME = "SET_THEME",
  SET_LAYOUT = "SET_LAYOUT",
  SIDENAV_EXPAND = "SIDENAV_EXPAND",
  SIDENAV_COLLAPSE = "SIDENAV_COLLAPSE",
  CLEAR_STORE = "CLEAR_STORE",
}
export enum AuthEnums {
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  CODE_EXPIRED = "ExpiredCodeException",
  RESET_REQUIRED = "PasswordResetRequiredException",
  CODE_MISMATCH = "CodeMismatchException",
  TOO_MANY_TRY = "LimitExceededException",
  USER_NOT_FOUND = "UserNotFoundException",
  USER_NOT_AUTHORIZED = "NotAuthorizedException",
  INVALID_PASSWORD = "InvalidPasswordException",
  INVALID_PARAMETER = "InvalidParameterException",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  NO_EMPTY = "EMPTY_STRING_NOT_ALLOWED",
  EMAIL_REQUIRED = "STRING_MUST_BE_EMAIL",
  PASSWORD_POLICY = "PASSWORD_MUST_FULFILL_POLICY",
  PASSWORD_CONFIRM_MISMATCH = "PASSWORD_AND_REPEAT_MUST_BE_SAME",
}

export enum EClientFiscalYear {
  JAN = "Jan",
  FEB = "Feb",
  MAR = "Mar",
  APR = "Apr",
  MAY = "May",
  JUN = "Jun",
  JUL = "Jul",
  AUG = "Aug",
  SEP = "Sep",
  OCT = "Oct",
  NOV = "Nov",
  DEC = "Dec",
}
