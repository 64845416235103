import {
  ApolloQueryResult,
  FetchResult,
  gql,
  MutationOptions,
  QueryOptions,
  useApolloClient,
} from "@apollo/client";
import { ILocalState, UserStateActionTypes } from "../../@types/index.d";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ICurrentUserAction } from "../store/reducers";
import { useSwitchClient } from "./useSwitchClient";
import {
  ClientInput,
  Client,
  LocalCacheEnums,
  localCache,
  CustomerState,
  SelectClientState,
  AccountMappingType,
  EClientFiscalYear,
} from "@canei/app-components";

export const clientDataQuery = `
    client_id
    name
    fiscal_year
    legal_form
    category
    mapping
    trade_registration
    founding_year
    address {
      city
      country
      line_1
      line_2
      postal
    }
    contact {
      first_name
      last_name
      telephone
      email
      website
    }
    customization {
      report_color
      dashboard_kpis
      potential_kpis
      upload_settings {
        format
        delimiter
        sheet_name
        variant_type
        account_code
        description
        balance
        balance_s
        balance_h
        balance_sh
        starting_balance
        starting_balance_s
        starting_balance_h
        starting_balance_sh
      }
      plan_settings {
        revenue_curve
        revenue_growth
        revenue_ratios {
          jan
          feb
          mar
          apr
          may
          jun
          jul
          aug
          sep
          oct
          nov
          dec
        }
      }
    }
    links {
      href
      rel
    }
  `;
const CLIENTS_LIST = gql`
  query clientsList($customer_id: ID!) {
    getClients(id: $customer_id) {
      ${clientDataQuery}
    }
  }
`;
const CREATE_NEW_CLIENT = gql`
  mutation newClient($data: ClientInput!) {
    createNewClient(data: $data) {
      ${clientDataQuery}
    }
  }
`;
const UPSERT_CLIENT = gql`
  mutation upsertClient($data: ClientInput!, $covid: String) {
    upsertClient(data: $data, covid: $covid) {
        ${clientDataQuery}
    }
  }
`;
const UPDATE_CLIENT_DATA = gql`
  mutation updateClient($params: UpdateClientInput!) {
    updateClientData(params: $params) {
        ${clientDataQuery}
    }
  }
`;
const DELETE_CLIENT = gql`
  mutation delete($customer_id: ID!, $client_id: ID!) {
    deleteClient(customer_id: $customer_id, client_id: $client_id)
  }
`;
const DEFAULT_KPI_VALUES = gql`
  {
    getDefaultKpiList
  }
`;
type TUseClients = () => IUseClientsResult;
type ClientListDataResult = { getClients: Client[] };
type TUseClientList = (
  options?: PartialQueryOptions
) => Promise<ApolloQueryResult<ClientListDataResult>>;
type TUseClientCreate = (
  data: ClientInput,
  options?: PartialMutationOptions
) => Promise<FetchResult>;
type TUseClientUpsert = (
  data: ClientInput,
  covid?: string,
  options?: PartialMutationOptions
) => Promise<FetchResult>;
type TUseClientRemove = (
  client_id: string,
  options?: PartialMutationOptions
) => Promise<FetchResult>;
type TUseClientUpdate = (options?: PartialQueryOptions) => void;
type TUseUpdateClientData = (
  params: TUpdatedClientInput,
  options?: PartialMutationOptions
) => Promise<FetchResult>;

interface IUseClientsResult {
  /**
   * Get updates list of available clients of the customer
   */
  list: TUseClientList;
  /**
   * Creates a new Client
   */
  create: TUseClientCreate;
  /**
   * Adds/removes/edits data of a client defined by client_id
   */
  upsert: TUseClientUpsert;
  /**
   * Removes a client defined with client_id
   */
  remove: TUseClientRemove;
  /**
   * Updates Clients list from server
   * */
  update: TUseClientUpdate;
  /**
   * Patches Clients Data
   * */
  updateClientData: TUseUpdateClientData;
}
type PartialQueryOptions = Partial<QueryOptions>;
type PartialMutationOptions = Partial<MutationOptions>;
type TUpdatedClientInput = {
  client_id: string;
  data: {
    fiscal_year: EClientFiscalYear;
    mapping?: AccountMappingType;
  };
};

export const useClients: TUseClients = () => {
  const apolloClient = useApolloClient();
  const { switchClient } = useSwitchClient();
  const dispatchCurrentUser = useDispatch<Dispatch<ICurrentUserAction>>();

  const { currentUser } = useSelector(({ currentUser }: ILocalState) => ({
    currentUser,
  }));
  const { client_id } = currentUser.selectedClient;
  const list: TUseClientList = (options = {}) =>
    apolloClient.query({
      ...options,
      query: CLIENTS_LIST,
      variables: {
        ...options.variables,
        customer_id: options.variables?.customer_id || currentUser.appUser.customer_id,
      },
    });
  const create: TUseClientCreate = (data: ClientInput, options = {}) => {
    return apolloClient.query({ query: DEFAULT_KPI_VALUES }).then(({ data: defaultData }) =>
      apolloClient
        .mutate({
          ...options,
          mutation: CREATE_NEW_CLIENT,
          variables: {
            data: {
              ...data,
              customization: {
                ...data.customization,
                dashboard_kpis: defaultData.getDefaultKpiList,
              },
            },
          },
        })
        .then((fetchResult) => {
          const newClient = fetchResult.data?.createNewClient;

          newClient !== undefined &&
            dispatchCurrentUser({
              type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
              payload: {
                clients: [...currentUser.clients, newClient],

                appUser: {
                  ...currentUser.appUser,
                  clients: [...currentUser.appUser.clients, newClient.client_id],
                },
              },
            });
          return fetchResult;
        })
    );
  };

  const upsert: TUseClientUpsert = (data: ClientInput, covid?: string, options = {}) => {
    return apolloClient
      .mutate<{ upsertClient?: Client }, { data: ClientInput; covid?: string }>({
        ...options,
        mutation: UPSERT_CLIENT,
        variables: { data: data, covid: covid },
      })
      .then((clientData) => {
        const { data } = clientData;
        const newClient = data?.upsertClient;
        if (newClient === undefined) return clientData;

        !covid &&
          dispatchCurrentUser({
            type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
            payload: {
              clients: currentUser.clients.map((client) =>
                client.client_id === newClient?.client_id ? newClient : client
              ),
            },
          });

        return clientData;
      });
  };
  const remove: TUseClientRemove = (client_id: string, options = {}) => {
    dispatchCurrentUser({
      type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
      payload: { clients: currentUser.clients.filter((client) => client.client_id !== client_id) },
    });
    apolloClient.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        getClients: (cachedClients: Client[]): Client[] =>
          cachedClients.filter((client) => client.client_id !== client_id),
      },
    });
    return apolloClient
      .mutate({
        ...options,
        mutation: DELETE_CLIENT,
        variables: {
          client_id,
          customer_id: currentUser.appUser.customer_id,
        },
      })
      .then((result) => {
        if (result.data?.deleteClient) {
          localCache.removeItem(LocalCacheEnums.CLIENT_LOGOS, {
            logo_hash: result.data?.deleteClient,
          });
        }
        return result;
      });
  };
  const update: TUseClientUpdate = (options = {}): void => {
    if (currentUser.state === CustomerState.PENDING) return;
    // dispatchCurrentUser({
    //   type: UserStateActionTypes.CURRENT_USER_STATE,
    //   payload: {
    //     state: CustomerState.PENDING,
    //   },
    // });
    list(options)
      .then(({ data }) => {
        const clients = data?.getClients as Client[];
        if (!clients) return;

        if (client_id !== null) {
          // If selected client is not available in newly updated client list,
          // reset client selection state
          const currentClientSelection = clients.find((c) => c.client_id === client_id);
          if (currentClientSelection === undefined) {
            switchClient(null, SelectClientState.UNKNOWN);
          }
        }

        dispatchCurrentUser({
          type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
          payload: { clients },
        });
      })
      .catch(() => {
        dispatchCurrentUser({
          type: UserStateActionTypes.CURRENT_USER_STATE,
          payload: {
            state: CustomerState.ERROR,
          },
        });
      });
  };
  const updateClientData: TUseUpdateClientData = (params: TUpdatedClientInput, options = {}) => {
    return apolloClient
      .mutate({
        ...options,
        mutation: UPDATE_CLIENT_DATA,
        variables: {
          params,
        },
      })
      .then((clientData) => {
        const { data } = clientData;
        const newClient = data?.updateClientData;
        if (newClient === undefined) return clientData;

        dispatchCurrentUser({
          type: UserStateActionTypes.UPDATE_CLIENTS_LIST,
          payload: {
            clients: currentUser.clients.map((client) =>
              client.client_id === newClient?.client_id ? newClient : client
            ),
          },
        });
        return clientData;
      });
  };
  return {
    list,
    create,
    upsert,
    remove,
    update,
    updateClientData,
  } as IUseClientsResult;
};
